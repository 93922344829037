@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Sans:100,200,300,400,500,600,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap");

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html, body, #root {
	display: flex;
	width: 100%;
	height: 100%;
	font-family: "IBM Plex Sans", sans-serif;
	flex: 1;
	overflow-x: hidden;
}

ul {
	outline: none;
}

body {
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	background: #f7f7f7;
}